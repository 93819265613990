<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("CAFETERIAS.ADD_CAFETERIA") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <cafeteria-form
              :loading="loading"
              :cafeteriaData="cafeteria"
              :formErrors="formErrors"
              @cafeteriaSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCafeteria from "./defaultCafeteria";
import CafeteriaForm from "./partials/CafeteriaForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CafeteriaForm,
  },

  mixins: [alertLeave],

  data() {
    const cafeteria = cloneDeep(defaultCafeteria);
    return {
      cafeteria: cafeteria,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Cafeterias" });
    },

    viewCafeteria(cafeteria) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Cafeteria",
        params: { id: cafeteria.id },
      });
    },

    async handleSubmit(cafeteria) {
      this.loading = true;
      const cafeteriaData = cloneDeep(cafeteria);

      try {
        await this.$store.dispatch("cafeterias/add", cafeteriaData);
        this.$notify({
          type: "success",
          message: this.$t("CAFETERIAS.CAFETERIA_ADDED"),
        });
        const cafeteria = await this.$store.getters["cafeterias/cafeteria"];
        this.viewCafeteria(cafeteria);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const cafeteria = await this.$store.getters["cafeterias/cafeteria"];
        if (cafeteria.id) {
          await this.$store.dispatch("cafeterias/destroy", cafeteria.id);
        }
        this.loading = false;
      }
    },
  },
};
</script>
